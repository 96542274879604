import React from "react";
import Link from './Link.jsx';
const ModalMenu = ({ menuItems, isToggled, toggle }) => {
  const classes = `modal fade p-0 ${isToggled ? 'show' : ''}`;
  return (
    <div id="menu" className={ classes } aria-hidden={isToggled ? 'true' : 'false'}>
      <div className="modal-dialog dialog-animated">
        <div className="modal-content h-100">
          <div className="modal-header" data-dismiss="modal">
            Menu
            <button onClick={toggle}>
              <svg className="icon-close" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
              </svg>
            </button>
          </div>
          <div className="menu modal-body">
            <ul className="navbar-nav items mx-auto">
              {menuItems.map((item, i) => (
                <li key={i} className={`nav-item ${item.children ? 'dropdown': ''}`}>
                  <Link title={item.title} href={item.href} classes="nav-link" />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalMenu;