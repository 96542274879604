import React from "react";
import useFollow from "../hooks/useFollow";
const Link = ({ title, href, classes, children }) => {
  const follow = useFollow();
  const handleClick = (e) => {
    if (follow) {
      e.preventDefault();
      follow({ title, href });
    }
  };
  return (
    <a title={title} href={href} className={classes} onClick={handleClick}>
      {children ? children : title}
    </a>
  );
};

export default Link;
