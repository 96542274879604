import React from 'react';
import Link from './Link';
import ProcessedHTML from "./ProcessedHtml";
import useImgX from "../hooks/useImgX";

const Teaser = ({ item }) => {
  const { title, excerpt, image, links, author, date } = item;
  const link = links.get('canonical');
  const teaser = useImgX(image.src, { w: 400 });
  const teaser1x = useImgX(image.src, { w: 400, dpr: 1 });
  const teaser2x = useImgX(image.src, { w: 400, dpr: 2 });
  return (
    <div className="blog__teaser">
      <div className="card blog-card">
        {image ? (
          <div className="blog-thumb">
            <Link title={image.alt} href={link.href}>
              <img src={teaser} alt={image.alt} srcSet={`${teaser1x} 1x, ${teaser2x} 2x`} />
            </Link>
          </div>
        ) : null}
        <div className="blog-content">
          <ul className="meta-info d-flex justify-content-between list-unstyled mt-4">
            {author ? <li>By <a href="#">{author}</a></li> : null}
            {date ? <li><a href="#">{date}</a></li> : null}
          </ul>
          <Link title={title} href={link.href}>
            <h4>{title}</h4>
          </Link>
          <ProcessedHTML html={excerpt} />
          {link ? <Link title={title} href={link.href} classes="btn content-btn">Read more</Link> : null}
        </div>
      </div>
    </div>
  )

}

export default Teaser;
