import React from 'react';

const SectionCenter = ({ label, modifier, children }) => (
    <section className={`section--center ${modifier}`}>
        <div className="container">
            <span className="section-label bg-text">{label}</span>
            {children}
        </div>
    </section>
)

export default SectionCenter;
