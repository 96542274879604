import { useState, useEffect } from "react";

const useMenuToggle = () => {
  const [isToggled, setToggle] = useState(false);

  const mq = typeof window !== 'undefined' ? window.matchMedia('(min-width: 640px)') : null;

  const unToggleOnResize = () => {
    if (mq.matches) {
      unToggle();
    }
  }
  const unToggle = () => {
    setToggle(false);
  }
  const handleEsc = (e) => {
    if (e.key === 'Escape') {
      unToggle();
    }
  }

  const handleToggle = (e) => {
    if (e) {
      e.preventDefault();
    }
    setToggle(!isToggled);
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (isToggled) {
        document.addEventListener('keydown', handleEsc);
      } else {
        document.removeEventListener('keydown', handleEsc);
      }
    }
  }, [isToggled]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      mq.addEventListener('change', unToggleOnResize);
    }
    return () => {
      if (typeof window !== 'undefined') {
        mq.removeEventListener('change', unToggleOnResize);
      }
    }
  }, [])

  return { isToggled, handleToggle }
}

export default useMenuToggle;
