import React from "react";
import Teaser from '../components/Teaser';
import EarlyAccessSignup from "../components/EarlyAccessSignup";
import Link from "../components/Link";

const Blog = ({ resource }) => {
  const { title, image, articles, links } = resource;
  const edit = links.get('edit-form');
  console.log({ edit });

  return (
    <>
      <section className="bg-banner breadcrumb-area overlay-dark d-flex align-items-center" style={{backgroundImage: `url(${image?.src})`}}>
        <div className="container">
          {edit
            ? <Link title="Edit" href={edit.href} classes={'edit-link'} />
            : null
          }
          <div className="breadcrumb-content text-center">
            <h2>{title}</h2>
          </div>
        </div>
      </section>
      <section className="blog-area">
        <div className="container">
          <div className="blog__teasers">
            {articles?.data.map((item) => (<Teaser item={item} key={item.id} />))}
          </div>
        </div>
      </section>
      <EarlyAccessSignup />
    </>
  )

}

export default Blog;
