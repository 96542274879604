import React from 'react';
import Home from './home';
import Blog from './Blog';
import Article from './Article';
import BasicPage from "./BasicPage";
import Pricing from "./Pricing";

const Page = ({ type, resource }) => {
    const types = {
        landing_page: Home,
        blog: Blog,
        article: Article,
        basic_page: BasicPage,
        pricing: Pricing
    }
    const PageType = types[type];
    return <PageType resource={resource} />
}

export default Page;
