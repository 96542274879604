import React from 'react';

const Card = ({ img, title, children }) => (
    <div className="card">
        {img}
        <h4 className="card__title">{title}</h4>
        {children}
    </div>
)

export default Card;
