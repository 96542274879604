import React from "react";
import SectionCenter from "./sectionCenter";
import LinkButton from "./linkButton";

const EarlyAccessSignup = () => (

  <SectionCenter label="Get Started" modifier="bg-transparent">
    <h3 className="mt-0">Sign up today</h3>
    <p>Create a site for free, or sign up for a premium plan.</p>
    <LinkButton link={{
      href: 'https://applura.app/launch',
      title: 'Sign up',
    }} />
  </SectionCenter>
);

export default EarlyAccessSignup;
