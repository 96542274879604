const useImgX = (src, params) => {
  const srcUrl = new URL(src);
  const searchParams = new URLSearchParams(params);
  const origin = 'https://applura-www.imgix.net';
  return searchParams.size > 0

    ? new URL(origin + srcUrl.pathname + '?' + searchParams.toString())
    : new URL(origin + srcUrl.pathname);
}

export default useImgX;
