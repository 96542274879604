import React from "react";
import ProcessedHTML from "../components/ProcessedHtml";
import Link from "./Link";


const BillingInterval = ({ billingInterval }) => (
  billingInterval ? <span className="price--interval">{billingInterval}</span> : null
)
const Price = ({ price, billingInterval, trialLength }) => {
  const formattedPrice = (new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(price));
  if (!price) {
    return <div className="price--not-shown">Contact us for pricing</div>
  }
  if (trialLength) {
    return (
      <>
        <div><span className="price--overridden">{formattedPrice}</span> <span className="price">$0</span><BillingInterval billingInterval={billingInterval} /></div>
        <div className="trial">Free for {trialLength}, then {formattedPrice}<BillingInterval billingInterval={billingInterval} /></div>
      </>
    )
  }
  return (
    <div><span className="price">{formattedPrice}</span><BillingInterval billingInterval={billingInterval} /></div>
  )
}
const Product = ({ product }) => {
  const { title, price, billingInterval, trialLength, content, features, payment } = product;
  return (
    <div className="product-card">
      <div className="product-content text-center">
        <h3 className="text-center">{title}</h3>
        <ProcessedHTML html={content} />
        <Price price={price} billingInterval={billingInterval} trialLength={trialLength} />
        {payment ? <Link title={payment.title} href={payment.href} classes={'btn btn-bordered-white my-8'} /> : null}
      </div>
      <ProcessedHTML html={features} classes="features" />
    </div>
  );
}

export default Product;
