import React, { useEffect, useRef } from "react";
import useFollow from "../hooks/useFollow";

/**
 * ProcessedHTML renders known-safe HTML.
 *
 * This component provides a clean method of rendering server-sent HTML. Applura pre-processes and filters server-sent
 * HTML against XSS vulnerabilities.
 *
 * @param html
 *   The known-safe HTML.
 */
const ProcessedHTML = ({ html, classes }) => {
  const follow = useFollow();
  const ref = useRef(null);
  
  const followLink = (e) => {
    e.preventDefault();
    follow(e.target.href);
  }

  useEffect(() => {
    const links = ref.current?.querySelectorAll('a') || [];
    links.forEach((link) => {
      link.addEventListener('click', followLink)
    });
    return () => {
      links.forEach((link) => {
        link.removeEventListener('click', followLink)
      });
    }
  }, [html, ref]);

  return (
    <div className={classes} ref={ref} dangerouslySetInnerHTML={{ __html: html }} />
  )
};

export default ProcessedHTML;
