import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import App from "./App";
import Socket from "./components/Socket";

import { bootstrap } from "@applura/client";
document.addEventListener("DOMContentLoaded", async () => {
  // Bootstrap the Applura client. This should wait for the "DOMContentLoaded" event since the "bootstrap" function
  // depends on <link> elements in the document <head>. They provide the URL for the current resource.
  const client = bootstrap();
  // Get the application container.
  const container = document.getElementById("app");
  const rewrittenUrlEl = document.querySelector('script[type="module"]');
  const rewrittenUrl = rewrittenUrlEl ? rewrittenUrlEl.src : '';
  const assetOrigin = rewrittenUrl ? rewrittenUrl.replace('/index.js', '') : null;
  // "Plug" the main application into the Applura client.
  const Main = <Socket App={App} client={client} assetContext={assetOrigin} />;
  // If the container has `data-server-rendered` attribute, then hydrate the application.
  if (container.hasAttribute("data-server-rendered")) {
    hydrateRoot(container, Main);
  } else {
    // Launch the application.
    createRoot(container).render(Main);
  }
});