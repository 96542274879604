import React from 'react';

const Section = ({ label, modifier, children }) => (
    <section className={modifier}>
        <div className="container">
        <span className="section-label text-hyphy bg-text">{label}</span>
            {children}
        </div>
    </section>
)

export default Section;
