import React from 'react';
import Page from './pages/page';
import ModalMenu from './components/ModalMenu';
import useToggle from "./hooks/useMenuToggle";
import NavBar from "./components/NavBar";
import Link from "./components/Link";
import AssetContext from "./context/AssetContext";

const App = ({ resource, problem, assetContext = null }) => {
  const { isToggled, handleToggle } = useToggle();

  const { type, mainMenu, footerMenu, siteName } = resource;

  if (typeof window !== 'undefined') {
    const bodyEl = document.querySelector('body');
    bodyEl.classList.toggle('modal-open', isToggled);
  }

  const menuItems = mainMenu?.data ? mainMenu.data.items : [];
  const footerItems = footerMenu?.data ? footerMenu.data.items : [];

  return (
    <AssetContext.Provider value={assetContext}>
    <div className="applura-public">
      <header>
        <NavBar toggle={handleToggle} menuItems={menuItems} />
      </header>
      <main>
        <Page type={type} resource={resource} />
      </main>
      <footer className="py-4">
        <div className="footer__inner container">
          <div className="footer__copyright-area">
            &copy; {new Date().getFullYear()} | {siteName || 'Applura'}
          </div>
          <nav className="footer__nav">
            <ul className="footer-menu">
              {footerItems.map((item, index) => (
                  <li key={index}>
                    <Link title={item.title} href={item.href} classes="nav-link"/>
                  </li>
              ))}
            </ul>
          </nav>
        </div>
      </footer>
      <ModalMenu isToggled={isToggled} toggle={handleToggle} menuItems={menuItems}/>
      {isToggled ? <div className="modal-backdrop show" onClick={handleToggle}/> : null}
    </div>
    </AssetContext.Provider>
  );

}

export default App;
