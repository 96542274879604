import React from "react";
import Link from "../components/Link";
import ProcessedHTML from "../components/ProcessedHtml";
const Article = ({ resource }) => {
  const { title, body, image, author, date, links } = resource;
  const edit = links.get('edit-form');
  return (
    <>
      <section className="bg-banner bg-banner--clouds2 breadcrumb-area overlay-dark d-flex align-items-center" style={{backgroundImage: `url(${image?.src})`}}>
        <div className="container">
          {edit
            ? <Link title="Edit" href={edit.href} classes={'edit-link'} />
            : null
          }
          <div className="breadcrumb-content text-center">
            <h2>{title}</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="post-details">
            <ProcessedHTML html={body} />
            <ul className="post-holder">
              <li className="post-meta-item">
                {date ? <span className="posted-on">Posted on {date}</span> : null}
                {author ? <span className="posted-by">by {author}</span> : null}
              </li>
            </ul>
          </div>
        </div>
      </section>
  </>
  );
}

export default Article;
