import React from "react";
import ProcessedHTML from "../components/ProcessedHtml";
import Link from "../components/Link";

const BasicPage = ({ resource }) => {
  const { title, body, image, links } = resource;
  const edit = links.get('edit-form');
  return (
    <>
      <section className="bg-banner breadcrumb-area overlay-dark d-flex align-items-center" style={{backgroundImage: `url(${image?.src})`}}>
        <div className="container">
          {edit
            ? <Link title="Edit" href={edit.href} classes={'edit-link'} />
            : null
          }
          <div className="breadcrumb-content text-center">
            <h2>{title}</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="page-details">
            <ProcessedHTML html={body} />
          </div>
        </div>
      </section>
  </>
  );
}

export default BasicPage;
